const config = {
  //配置默认语言
  // Language: "tur",
  Language :'vn',
  // ApiUrl: "https://de567.com",
  ApiUrl: "https://sn288.com",
  // ApiUrl: "https://27wk.com",

};
export default config;
