import Vue from "vue";
import { Locale } from "vant";
import VueI18n from "vue-i18n";

import Chinese from "./cn";
import English from "./en";
import fr from "./fr-fr";

import enUS from "vant/lib/locale/lang/en-US";
import zhCN from "vant/lib/locale/lang/zh-CN";
import tc from "./tc";
import th from "./th";

import vn from "./vn";
import config from "@/config";

//设置默认语言
var Language = config.Language;

/*初始化*/
const lang = localStorage["Language"] || Language;

let locale = "id-ID";
switch (lang) {
  case "en":
    locale = "en-US";
    Locale.use("en-US", enUS);
    break;
  case "vn":
    locale = "vn";
    Locale.use("vn", vn);
    break;
  case "fr":
    locale = "fr";
    Locale.use("fr", fr);
    break;
  case "th":
    locale = "th";
    Locale.use("th", th);
    break;
  case "tc":
    locale = "zh-HK";
    Locale.use("zh-HK", tc);
    break;
  case "cn":
    locale = "zh-CN";
    Locale.use("zh-CN", zhCN);
    break;
}

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: locale,
  messages: {
    "zh-CN": Chinese,
    "en-US": English,
    "zh-HK": tc,
    "vn": vn,
    "fr": fr,
    "th": th,
  },
});

/*切換*/
export function SetLanguage(lang) {
  i18n.locale = lang;
  switch (lang) {
    case "en-US":
      localStorage["Language"] = "en";
      Locale.use("en-US", enUS);
      break;
    case "th":
      localStorage["Language"] = "th";
      Locale.use("th", th);
      break;
    case "vn":
      localStorage["Language"] = "vn";
      Locale.use("vn", vn);
      break;
    case "zh-HK":
      localStorage["Language"] = "tc";
      Locale.use("zh-HK", tc);
      break;
    case "zh-CN":
      localStorage["Language"] = "cn";
      Locale.use("zh-CN", zhCN);
      break;
  }
}
export default i18n;
