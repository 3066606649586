export default {
  mixRecharge: ["กรุณากรอกที่อยู่อีเมลของคุณ"],
  language: "ภาษา",
  common: ["บริการออนไลน์", "ยกเลิกการระงับ","ยืนยัน","ยกเลิก"],
  upload: ["กำลังอัปโหลด...", "รูปแบบผิด", "อัปโหลดสำเร็จ", "การอัพโหลดล้มเหลว"],
  vanPull: ["ไม่มีข้อมูลเพิ่มเติม", "ไม่มีข้อมูล"],
  login: {
    text: ["ภาษา","Crypto","เข้าสู่ระบบ"],
    label: ["เข้าสู่ระบบ...", "เข้าสู่ระบบทันที"],
    placeholder: ["กรุณากรอกอีเมล์/เบอร์มือถือ", "กรุณาใส่รหัสผ่านของคุณ"],
    default: ["ไม่มีบัญชี?", "สมัครตอนนี้เลย","ลืมรหัสผ่าน?","ลืมรหัสผ่านหรือไม่"],
    codes:["ไม่มีบัญชี","รหัสผ่านผิดพลาด","บัญชีถูกระงับ","ล้มเหลว"]
  },
  register: {
    text: [
      "Crypto",
      "กำลังส่งรหัสยืนยัน...",
      "คลิกเพื่อรับ",
      "กำลังลงทะเบียน...",
      "สมัครตอนนี้เลย",
    ],
    placeholder: [
      "กรุณาใส่อีเมลของคุณ",
      "กรุณากรอกรหัสยืนยัน",
      "กรุณาใส่รหัสผ่านของคุณ",
      "กรุณายืนยันรหัสผ่านของคุณ",
      "กรุณากรอกรหัสเชิญ",
      "สองรหัสผ่านที่แตกต่างกัน",
      "กรุณากรอกรหัสยืนยัน",
      "อีเมลไม่ถูกต้อง"
    ],
    label: ["มีบัญชีอยู่แล้ว?{a} {line}", "กลับเข้าสู่ระบบ"],
    codes:["บัญชีมีอยู่แล้ว","ไม่มีรหัสยืนยัน","รหัสยืนยันไม่ถูกต้อง","รหัสผ่านทั้งสองไม่ตรงกัน","ไม่มีผู้อ้างอิง","ล้มเหลว"]
  },
  resetpwd:["ลืมรหัสผ่าน","กรุณาใส่อีเมลของคุณ","กรุณากรอกรหัสยืนยัน","คลิกเพื่อรับ","กรุณาใส่รหัสผ่าน","รีเซ็ตรหัสผ่าน"],
  footer: ["หน้าแรก", "รายการ", "คำสั่งซื้อ", "ประวัตของฉัน"],

  home: {
    label:["ยินดีต้อนรับ"],
    broadcast: "ขอแสดงความยินดีสมาชิก{member} กลายเป็น{grade}",
    menu: ["บันทึกกองทุน", "แนะนำ", "ลงทะเบียน", "บริการลูกค้า"],
    noticeTitle: "ประกาศล่าสุด",
    msg: "ภารกิจไม่เปิด",
    video: "ไม่มีวิดีโอสอน",
  },
  hall:{
    default:[
      "ชั้นซื้อขาย","สมดุล","ฉันต้องการที่จะซื้อ","ฉันต้องการขาย","ยืนยันการขาย",
      "รายการสั่งซื้อ","สั่งขาย","ขายให้กับพ่อค้า","คำสั่งซื้อที่รอดำเนินการ","ซื้อ","ขาย"
    ],
    list:[
      'ปริมาณ','ราคาต่อหน่วย','ที่เหลืออยู่','ขาย USDT','โปรดป้อนจำนวนเงิน USDT','รับจำนวนเงิน',"โควต้า",'ซื้อ USDT','จำนวนเงินที่ใช้ไป'
    ],
    kbip:["ถอน","จำนวนเงินที่มากเกินไป","ต้องอยู่ในวงเงิน"]
  },
  msglist:[
    "รายการข้อความ"
  ],
  sell:{
    placeholder:["กรุณาใส่ราคาขาย","กรุณาใส่ปริมาณการขาย"],
    label:["ยอดคงเหลือ (USDT)","ชื่อ","บัญชีธนาคาร","ราคาปัจจุบัน(USDT)","ขายราคาดีที่สุด","ราคาขาย","ขายปริมาณ USDT","ราคาที่ดีที่สุด","ทั้งหมด","ตกลงที่จะขาย"]
  },
  buy:{
    placeholder:["กรุณาใส่ราคาซื้อ","กรุณากรอกจำนวนซื้อ","จำนวนเงินขั้นต่ำ","จำนวนเงินสูงสุด"],
    label:["สมดุล(USDT)","ชื่อ","บัญชีธนาคาร","ราคาปัจจุบัน(USDT)","ซื้อราคาที่ดีที่สุด","ราคาซื้อ","ซื้อปริมาณ","ราคาที่ดีที่สุด","ทั้งหมด","ยืนยันการซื้อ","โควต้า"]
  },
  user: {
    default: [
      "ศูนย์ส่วนบุคคล",
      "เข้าสู่ระบบบัญชี",
      "รหัสการเชิญ",
      "ออกจากระบบ",
      "สมดุล",
      "ทอง",
      "กระเป๋าสตางค์",
    ],
    menu: [
      "ทรัพย์สินของฉัน",
      "ทีม",
      "การผูกบัญชี",
      "รายละเอียดบัญชี",
      "บทนำแพลตฟอร์ม",
      "ออกจากระบบ",
    ],
  },
  bindAccount: {
    default: ["ผูกบัญชี","ส่ง"],
    fields: ["ชื่อ", "หมายเลขโทรศัพท์", "หมายเลขบัญชี","ธนาคาร","ชนิดของ","ที่อยู่ USDT","รหัสธนาคาร"],
    fields2: ["ชื่อ-นามสกุล", "เบอร์โทรศัพท์", "หมายเลขบัญชี","ธนาคาร","ประเภท","ที่อยู่ USDTl"],
    placeholder: ["ชื่อบัญชี","หมายเลขโทรศัพท์","เลขที่บัญชี","กรุณากรอกชื่อธนาคาร",
      "กรุณาเลือกประเภท USDT","โปรดป้อนที่อยู่ USDT"],
    placeholder2: ["กรุณากรอกชื่อและนามสกุล","กรุณากรอกหมายเลขโทรศัพท์","กรุณากรอกหมายเลขบัญชีของคุณ",
      "ชื่อธนาคาร",
      "กรุณาเลือกประเภท USDT","กรุณากรอกที่อยู่ USDT"],
  },
  wallet: {
    default: [
      "ทรัพย์สินของฉัน",
      "เติมเงิน",
      "ถอน",
      "บันทึกการเติมเงิน",
      "บันทึกการถอนเงิน",
      "จำนวนเงินที่เติม",
      "จำนวนที่ขาย",
      "ได้รับ",
      "ยอดเงินในบัญชี",
      "ยอดเงินคงเหลือ",
      "จำนวนเงินที่เติม(USDT)",
      "ยอดขาย",
      "รายได้รวม",
      "ยกระดับธุรกิจ",
      "อัพเกรดร้านค้า กรุณาติดต่อฝ่ายบริการลูกค้า",
      "ยืนยันการตกลงทางธุรกิจ",
      "การรับรองความถูกต้องของผู้ค้า",
      "คุณต้องจ่ายเงิน","ใบสำคัญจ่าย","ฝากค้า","รับรองความถูกต้องในขณะนี้","ข้อมูล","คุณต้องจ่ายเงิน","จะเติมเงินหรือไม่หากยอดเงินไม่เพียงพอ"
    ],
    label: [
      "วิธีการถอนเงิน",
      "จำนวนเงินที่ถอนออก",
      "รหัสผ่านกองทุน",
      "ส่ง",
      "หมายเลขโทรศัพท์",
      "จดหมาย",
      "IFSC",
      "แน่นอน",
    ],
    placeholder: [
      "เลือกวิธีการถอนเงิน",
      "กรุณากรอกจำนวนเงินที่ถอน",
      "กรุณากรอกรหัสผ่านกองทุน",
      "กรุณาเลือกวิธีการถอนเงิน",
      "กรุณากรอกหมายเลขโทรศัพท์มือถือของผู้รับเงิน",
      "กรุณากรอกที่อยู่อีเมลของผู้รับ",
      "กรุณาระบุผู้รับผลประโยชน์ IFSC",
    ],
    msg: [
      "คุณยังไม่ได้ตั้งรหัสผ่านของกองทุน กรุณาตั้งรหัสผ่านก่อน",
      "คุณยังไม่ได้ผูกบัญชี PIX โปรดผูกบัญชีก่อน",
      "ยืนยันการถอน?",
    ],
  },
  recharge: {
    default: [
      "เติมเงินกระเป๋าสตางค์",
      "การเติมเงินล่วงหน้าของกระเป๋าเงิน",
      "วิธีการเติมเงิน",
      "เติมเงิน",
      "ยืนยัน",
    ],
    label: [
      "หมวดหมู่",
      "ที่อยู่",
      "จำนวนเงินที่เติม",
      "ป้อนจำนวนเงิน",
      "อัปโหลดใบรับรอง",
      "คัดลอกสำเร็จ",
      "การแลกเปลี่ยนเงินฝากล่วงหน้า"
    ],
    placeholder: [
      "ชนิดของ",
      "ที่อยู่",
    ],
    info: [
      "จำนวนเงินที่เติม",
      "หมายเลขคำสั่งซื้อ",
      "ธนาคารผู้รับผลประโยชน์",
      "บัญชีเงินเก็บ",
      "ผู้รับเงิน",
      "สำเนา",
    ]
  },
  task: {
    tabs: ["ทั้งหมด","กำลังดำเนินการ", "หมดอายุ", "สมบูรณ์","รอแม่ค้าชำระเงิน","รอการยืนยัน"],
    default: ["รายการสั่งซื้อ",],
    msg: ["ส่งรีวิว", "ส่งแล้ว กรุณารอการตรวจสอบ", "การส่งล้มเหลว โปรดส่งใหม่"],
  },
  userInfo: {
    default: [
      "ข้อมูลส่วนบุคคล",
      "สัญลักษณ์",
      "หมายเลขบัญชี",
      "จดหมาย",
      "อาลีเพย์",
      "รายละเอียดข้อมูล",
      "เปลี่ยนรหัสผ่าน",
      "รหัสผ่านกองทุน",
      "คลิกการตั้งค่า",
      "แก้ไขอวาตาร์",
      "เปลี่ยนรหัสผ่านเข้าสู่ระบบ",
      "เปลี่ยนรหัสผ่านกองทุน",
      "ส่ง",
      "ล้างแคช",
      "ดูข้อมูล",
      "ชื่อเล่น",
      "รหัสอ้างอิง"
    ],
    label: [
      "รหัสผ่านเข้าสู่ระบบเดิม",
      "รหัสผ่านเข้าสู่ระบบใหม่",
      "ยืนยันรหัสผ่าน",
      "รหัสผ่านกองทุนเดิม",
      "รหัสผ่านกองทุนใหม่",
      "ยืนยันรหัสผ่าน",
      "บันทึก"
    ],
    placeholder: [
      "กรุณาป้อนรหัสผ่านเข้าสู่ระบบเดิม",
      "กรุณาป้อนรหัสผ่านเข้าสู่ระบบใหม่",
      "กรุณายืนยันรหัสผ่านเข้าสู่ระบบ",
      "กรุณากรอกรหัสผ่านกองทุนเดิม",
      "กรุณากรอกรหัสผ่านกองทุนใหม่",
      "กรุณายืนยันรหัสผ่านกองทุน",
    ],
  },
  fundRecord: {
    default: ["บันทึกค่าใช้จ่าย", "บันทึกการเติมเงิน", "บันทึกสินทรัพย์", "ชาร์จ", "รับ", "สนับสนุน"],
    tabs: ["รายรับ","รายจ่าย","เติมเงิน"],
  },
  dialog: [
    "คำใบ้",
    "แน่นอน",
    "กำลังส่ง...",
    "คัดลอกสำเร็จ",
    "เวอร์ชันระบบ IOS ต่ำและไม่รองรับ",
    "กำลังลงทะเบียน...",
    "กำลังโหลด...",
  ],
  serviceCenter: [
    "บริการลูกค้า",
    "สวัสดี ฉันคือฝ่ายบริการลูกค้าพิเศษ~",
    "ยินดีให้บริการค่ะ",
    "บริการตนเอง",
    "บริการลูกค้าออนไลน์",
    "เติมเงินบริการลูกค้า",
    "บริการลูกค้าสาย",
  ],
  userTaskRecord: [
    "คำสั่งของฉัน",
    "คำสั่งขาย",
    "คำสั่งซื้อ",
    "สถานะปัจจุบัน",
    "รับคอมมิชชั่น",
    "สมบูรณ์",
  ],
  withdrawlist: [
    "บันทึกการถอนเงิน",
  ],
  teamReport: {
    default: [
      "รายชื่อทีม",
    ],
  },
  common2:['บทช่วยสอนสำหรับผู้เริ่มต้น', "ติดต่อฝ่ายบริการลูกค้า", "ข้อกำหนดในการให้บริการ, ดูรายละเอียด", "ฝ่ายบริการลูกค้า 1", "ฝ่ายบริการลูกค้า 2"],
  common3:['ความสำเร็จ',"การแนะนำแพลตฟอร์ม"],
  invite:['แชร์โปรโมชัน','รหัสเชิญของฉัน',"คัดลอกรหัสเชิญเพื่อเชิญเพื่อนให้เข้าร่วม","คัดลอก"],
  common4:['ตรวจสอบชื่อจริง',"ส่งสำเร็จ","หมายเหตุ","เนื้อหารายการ","จำนวนเงินที่ชำระ","หน้าบัตรประชาชน",
    "หลักฐานการเงิน", "กรุณาผูกบัญชีให้เรียบร้อยก่อน", "กรุณาตรวจสอบชื่อจริงให้เสร็จก่อน"],
  common5:["ขาย", "นาที", "ก่อนหน้านี้", "บันทึกคิวอาร์โค้ด", "เปิด", "บัญชีถูกระงับ ไม่สามารถซื้อขายได้ชั่วคราว", "ดาวน์โหลดแอป"],
  common6:["คำสั่งเติมเงิน", "หมายเลขคำสั่งซื้อ", "จำนวนเงิน", "เวลา", "สถานะ"],
  hall2:["ยอดขายขั้นต่ำคือ","","จำนวนการขายเกินขีดจำกัดรายวัน"],
  register2:["การลงทะเบียนอีเมล", "การลงทะเบียนโทรศัพท์มือถือ", "กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ"],
  withdraw2:["การถอน", "บัญชีผู้ขาย", "ยอดคงเหลือในบัญชี", "จำนวนเงินที่ต้องชำระ", "ชำระเงินทันที", "ยังมีงานที่ยังไม่เสร็จสมบูรณ์และการถอนถูกปิดใช้งานชั่วคราว", "จำนวนการถอนต่อ เกินวัน", "หมายเหตุ"],
  mytask2:["บัญชีร้านค้า", "ราคา", "จำนวนเงิน", "ยอดเงินในบัญชี", "จำนวนเงินที่ชำระ", "เวลา", "รายละเอียดการสั่งซื้อ", "ปริมาณไม่สามารถเป็น 0", "ยอดคงเหลือไม่เพียงพอ", "ราคาไม่สามารถเป็น 0" ,"ช่วงไม่ถูกต้อง"],
  taskOrder2:["คุณยังมีคำสั่งซื้อที่ยังไม่สำเร็จซึ่งไม่สามารถยกเลิกได้", "ยอดเงินคงเหลือไม่เพียงพอ", "ยืนยันแล้ว", "ล้มเหลว", "คุณยังคงต้องทำคำสั่งซื้อให้เสร็จสมบูรณ์เพื่อถอนเงินสด:"],
  busAuth2:['อัปเกรดธุรกิจ', 'ฉันยอมรับ', "ยืนยันการอัปเกรด", "อัปเกรดร้านค้า", "โปรดติดต่อฝ่ายบริการลูกค้าหากคุณต้องการแก้ไขข้อมูลบัญชี", "บัญชีปัจจุบันจำเป็นต้องอัปเกรดเป็นบัญชีร้านค้า"],
  recharge2:['จำนวนเงินไม่สามารถเป็น 0', 'ต้องอัปโหลดภาพหน้าจอ'],
  buy3:["นับถอยหลัง", "ชั่วโมง", "ประเภท", 'ใบสั่งสวัสดิการ', 'ใบสั่งขายร่วม', 'จำกัดจำนวนคน', 'ไม่ป้อนไม่จำกัดจำนวนคน', 'รหัสผู้ใช้ที่ระบุ', 'กรุณากรอก รหัสที่ระบุ'],
  hall3:["วัน", "ชั่วโมง", "นาที", "วินาที", "คำสั่งผสม", "บัญชี", 'หมดอายุ'],
  sell3:['ราคาขายต้องไม่เกินราคาที่ดีที่สุด'],
  busAuth3:["สมาชิก", "ผู้ค้า", "ผู้ค้ามงกุฎ"],
  recharge3:["คำนวณ"],
  home3:["ขุดเหรียญ", "กล่องสุ่ม", "กล่องปริศนา", "กระเป๋าเงิน", "คอยติดตาม"],
  home4:["ธุรกรรมด่วน", "ซื้อ USDT ในคลิกเดียว", "ธุรกรรม C2C", "ซื้อและขาย USDT", 'ผู้ใช้ออนไลน์', "ปริมาณธุรกรรม 24 ชั่วโมง (USDT)"],
  common7:["หากปัญหาของคุณยังไม่ได้รับการแก้ไข โปรดติดต่อฝ่ายบริการลูกค้าออนไลน์", "ติดต่อฝ่ายบริการลูกค้า", "คลิกเพื่อปรึกษา", "การตั้งค่า", "ทั้งหมด", "เชิญเพื่อน", "จำนวนคนในทีม", " เพิ่มเดือนนี้", "เวลาลงทะเบียน", "จำนวนผู้ใต้บังคับบัญชา"],
  hall4:['ข้อมูลประจำตัวของคุณ', 'สมาชิก', "ข้อมูลบัญชีของคุณ", "ธุรกรรมสำเร็จ", "คุณทำสำเร็จแล้ว", "ราคารวม", "ซื้อ", "ขาย"],
  task3:["ทั้งหมด","ค้างชำระ","ชำระแล้ว"],
  my:["คุณแน่ใจหรือไม่ว่าต้องการออกจากระบบบัญชีของคุณ?"],
  bindAccount2:["กรุณากรอกข้อให้ถูกต้อง มิฉะนั้น จะมีผลต่อการทำธุระกรรม", "ผูกมัดทันที","ติดต่อฝ่ายบริการ"],
  recharge4:["เพื่อความปลอดภัยของท่าน หลังจากโอนเงินสำเร็จโปรดส่งหลักฐานการโอนเพื่อยืนยันและตรวจสอบ!"],
  resetpwd2:['อีเมล','โทรศัพท์มือถือ','หมายเหตุ: รหัสผ่านประกอบด้วย 6~16 ตัวอักษรและไม่คำนึงถึงตัวพิมพ์เล็กและใหญ่'],
  hall5:["จำนวน"]
};
