export default {
  mixRecharge: ["請填寫郵箱地址"],
  language: "語言",
  common: ["在線客服", "取消懸浮","確認","取消"],
  upload: ["上傳中...", "錯誤格式", "上傳成功", "上傳失敗"],
  vanPull: ["沒有更多數據了", "沒有數據"],
  login: {
    text: ["語言","Crypto","登錄"],
    label: ["正在登錄...", "立即登錄"],
    placeholder: ["請輸入郵箱/手機號碼", "請輸入登錄密碼"],
    default: ["沒有賬號？", "立即註冊","忘記密碼?","忘記登錄密碼"],
    codes:["賬號不存在","密碼不正確","賬號被凍結","失敗"]
  },
  register: {
    text: [
      "Crypto",
      "驗證碼發送中...",
      "點擊獲取",
      "正在註冊...",
      "立即註冊",
    ],
    placeholder: [
      "請輸入郵箱",
      "請輸入驗證碼",
      "請輸入登錄密碼",
      "請確認您的密碼",
      "請輸入邀請碼",
      "兩次密碼不一樣",
      "請輸入驗證碼",
      "無效郵箱"
    ],
    label: ["已有賬號？{a} {line}", "返回登錄"],
    codes:["賬號已存在","沒有驗證碼","驗證碼不正確","兩次密碼不一致","推薦人不存在","失敗"]
  },
  resetpwd:["忘記密碼","請輸入郵箱","請輸入驗證碼","點擊獲取","請輸入密碼","重置密碼"],
  footer: ["首頁", "大廳", "訂單", "我的"],

  home: {
    label:["歡迎"],
    broadcast: "恭喜會員{member} 成為{grade}",
    menu: ["資金日誌", "新手教程", "邀請註冊", "聯繫客服"],
    noticeTitle: "最新公告",
    msg: "任務未開放",
    video: "暫無視頻教程",
  },
  hall:{
    default:[
      "交易大廳","餘額","我要買","我要賣","確認賣出",
      "訂單列表","卖出訂單","卖给商家","掛單中","買入","去出售"
    ],
    list:[
      '數量','單價','剩余','出售USDT','請輸入USDT數量','獲得金額',"限額",'買入USDT','耗費金額'
    ],
    kbip:["撤銷","超出金額","必須在金額範圍內"]
  },
  msglist:[
    "消息列表"
  ],
  sell:{
    placeholder:["請輸入賣出價格","請輸入賣出數量"],
    label:["餘額(USDT)","姓名","銀行帳號","當前價格(USDT)","賣出最優價格","賣出價格","賣出USDT數量","最優價格","全部","確定賣出"]
  },
  buy:{
    placeholder:["請輸入買入價格","請輸入買入數量","最小額度","最大額度"],
    label:["餘額(USDT)","姓名","銀行帳號","當前價格(USDT)","買入最優價格","買入價格","買入數量","最優價格","全部","確認買入","額度"]
  },
  user: {
    default: [
      "個人中心",
      "登錄帳號",
      "邀請碼",
      "退出登錄",
      "餘額",
      "金幣",
      "錢包",
    ],
    menu: [
      "我的資產",
      "團隊列表",
      "帳戶綁定",
      "帳戶明細",
      "平臺介紹",
      "退出登錄",
    ],
  },
  bindAccount: {
    default: ["綁定帳號","提交"],
    fields: ["姓名", "手機號", "帳號","銀行","類型","USDT地址","銀行代码"],
    fields2: ["账户名", "电话号码", "账号","银行名称","类型","地址"],
    placeholder: ["請輸入開戶姓名","請輸入手機號碼","請輸入銀行帳號","請輸入開戶行名稱",
      "請選擇USDT類型","請輸入USDT地址"],
    placeholder2: ["请输入账户名","请输入电话号码","请输入账号",
      "请输入银行名称",
      "Vui lòng chọn loại USDT","请输入USDT地址"],
  },
  wallet: {
    default: [
      "我的資產",
      "充值",
      "提現",
      "充值記錄",
      "提現記錄",
      "已充值金額",
      "已賣出金額",
      "已獲取收益",
      "帳號餘額",
      "餘額",
      "充值金額(USDT)",
      "賣出金額",
      "總收益",
      "升級商家",
      "升級商家，請聯繫客服",
      "確認入駐商家",
      "商家認證",
      "您需支付","支付憑證","商家保證金","立即認證","資訊","您需支付","餘額不足是否充值"
    ],
    label: [
      "提現方式",
      "提現金額",
      "資金密碼",
      "提交",
      "手機號",
      "郵箱",
      "IFSC",
      "確定",
    ],
    placeholder: [
      "選擇提現方式",
      "請輸入提現金額",
      "請輸入資金密碼",
      "請選擇提現方式",
      "請輸入收款人手機號",
      "請輸入收款人郵箱",
      "請輸入收款人IFSC",
    ],
    msg: [
      "你還未設置資金密碼，請先設置",
      "你還未綁定PIX帳戶，請先綁定",
      "確定要提現?",
    ],
  },
  recharge: {
    default: [
      "錢包充值",
      "錢包預充值",
      "充值方式",
      "充值",
      "確認",
    ],
    label: [
      "類別",
      "地址",
      "充值金額",
      "輸入金額",
      "上傳憑證",
      "拷貝成功",
      "預充值交易所"
    ],
    placeholder: [
      "類型",
      "地址",
    ],
    info: [
      "充值金額",
      "訂單號",
      "收款銀行",
      "收款帳號",
      "收款人",
      "複製",
    ]
  },
  task: {
    tabs: ["全部","進行中", "已失效", "已完成","等待商家支付","等待確認"],
    default: ["訂單列表",],
    msg: ["提交審核", "已提交，請等待審核", "提交失敗，請重新提交"],
  },
  userInfo: {
    default: [
      "個人資訊",
      "頭像",
      "帳號",
      "郵箱",
      "支付寶",
      "詳細資訊",
      "修改密碼",
      "資金密碼",
      "點擊設置",
      "修改頭像",
      "修改登錄密碼",
      "修改資金密碼",
      "提交",
      "清空緩存",
      "查看資訊",
      "昵稱",
      "推薦碼"
    ],
    label: [
      "原登錄密碼",
      "新登錄密碼",
      "確認密碼",
      "原資金密碼",
      "新資金密碼",
      "確認密碼",
      "保存"
    ],
    placeholder: [
      "請輸入原登錄密碼",
      "請輸入新登錄密碼",
      "請確認登錄密碼",
      "請輸入原資金密碼",
      "請輸入新資金密碼",
      "請確認資金密碼",
    ],
  },
  fundRecord: {
    default: ["支出記錄", "充值記錄", "資產日誌", "充", "收", "支"],
    tabs: ["收入", "支出", "充值"],
  },
  dialog: [
    "提示",
    "確定",
    "正在提交...",
    "複製成功",
    "IOS系統版本低不支持",
    "正在註冊...",
    "數據加載中...",
  ],
  serviceCenter: [
    "客服中心",
    "Hi,我是專屬客服~",
    "很高興為您服務",
    "自助服務",
    "線上客服",
    "充值客服",
    "Line客服",
  ],
  userTaskRecord: [
    "我的訂單",
    "賣出訂單",
    "買入訂單",
    "當前狀態",
    "獲得傭金",
    "已完成",
  ],
  withdrawlist: [
    "提現記錄",
  ],
  teamReport: {
    default: [
      "團隊列表",
    ],
  },
  common2:['新手教程',"聯繫客服","服務條款，查看詳情","客服1","客服2"],
  common3:['成功',"平臺介紹"],
  invite:['分享推廣','我的邀請碼',"複製邀請碼，邀請更多好友加入","複製"],
  common4:['實名認證',"提交成功","注意事項","事項內容","繳納金額","身份證正面",
    "財務證明","請先去完成帳號綁定","請先完成實名認證"],
  common5:["賣出","分鐘","前","保存二維碼","打開","帳號凍結,暫時無法交易","APP下載"],
  common6:["充值訂單","訂單號","金額","時間","狀態"],
  hall2:["最低出售金額為","","賣出次數超出每天限制"],
  register2:["郵箱註冊","手機註冊","請輸入手機號碼"],
  withdraw2:["提現","賣家帳號","帳戶餘額","需支付金額","立即支付","還有任務未完成,暫時不能提現","每天提現次數超出","備註說明"],
  mytask2:["商家帳號","價格","金額","帳號餘額","需付款金額","時間","訂單詳情","數量不能為0","餘額不足","價格不能為0","範圍錯誤"],
  taskOrder2:["你還有未成交訂單，不能撤銷","餘額不足","確認","失敗","你還需要完成訂單才能提現:"],
  busAuth2:['商家升級','我同意',"確認升級","升級商家","需要修改銀行卡資訊請聯繫客服","當前帳號需要升級為商家帳號"],
  recharge2:['金額不能為0','必須上傳截圖'],
  buy3:["倒計時","小時","類型",'福利訂單','合卖訂單','限制人数','不输入不限制人数','指定用户ID','请输入指定ID'],
  hall3:["天","小時","分鐘","秒","合卖訂單","帳號",'已到期'],
  sell3:['賣出價格不能大於最優價'],
  busAuth3:["會員","商家","皇冠商家"],
  recharge3:["计算"],
  home3:["矿机","竞猜","盲盒","理财","敬请期待"],
  home4:["快捷交易","一键购买USDT","C2C交易","买卖USDT",'在线用户',"24H交易总量(USDT)"],
  common7:["若未解决你的问题，请联系在线客服","联系客服","点击咨询","设置","全部","邀請好友","團隊人数","本月新增","註冊时间","下属人数"],
  hall4:['你的身份','会员',"你的账户信息","交易成功","你已成功完成","总价","求购","出售"],
  task3:["全部","未支付","已支付"],
  my:["你確定要退出帳號?"],
  bindAccount2:["清确认信息填写无误，否则将影响正常交易","立即绑定","联系客服"],
  recharge4:["为了你的资金安全,转账成功后，清提交转账成功截图，以便审核!"],
  resetpwd2:['郵箱','手機','注意: 密码由6~16个字符组成，不区分大小写'],
  hall5:["余额"],
  team2:["队伍详情","邀请好友"]
};
